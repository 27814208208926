<template>
  <div>
    <v-btn color="green" class="" icon x-large @click="dialog = true"
      ><v-icon>mdi-plus-box</v-icon></v-btn
    >
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title>Add Batch Item Import</v-card-title>
        <v-card-text>
          <v-text-field
            label="Batch Description"
            v-model="form.batch_description"
            prepend-icon="mdi-list-box"
          ></v-text-field>
          <v-select
            :items="suppliers"
            v-model="form.supplier_id"
            item-text="supplier_name"
            item-value="id"
            label="Supplier"
            prepend-icon="mdi-account-group"
          ></v-select>
          <v-text-field
            label="Capital Amount"
            prepend-icon="mdi-cash"
            v-model="form.capital_amount"
          ></v-text-field>
          <div class="d-flex">
            <p class="mr-2">Date Ordered</p>
            <input type="date" v-model="form.date_ordered" />
          </div>
          <div class="d-flex">
            <p class="mr-2">Date Arrived</p>
            <input type="date" v-model="form.date_arrived" />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit" :loading="loading"
            >Submit</v-btn
          >
          <v-btn @click="dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<!-- TODO finish this -->
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      form: {},
      dialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      suppliers: "supplier/suppliers",
    }),
  },
  methods: {
    ...mapActions({
      add_import_batch: "importBatch/add_import_batch",
    }),
    submit() {
      this.loading = true;
      let x = window.confirm("Are you sure you want to proceed?");
      if (x) {
        this.add_import_batch(this.form).then(() => {
          alert("You have successfully added a new batch import");
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
